const useCoinDrops = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const { data: coinDropsData, refresh: refreshCoinDropsData } = useAsyncFetch({
		path: "/rest/prize-drops/info/",
		method: "get",
		options: {
			immediate,
			cached: true,
			default: () => ({
				general: {
					dailyPrizePool: 100000,
					highestPrizePerDay: 5000,
					prizeCountPerDay: 500,
					minimalLevelPlay: 20,
					finishedAt: "",
					nextPrizePoolStartedAt: ""
				},
				prizes: [],
				lastWinners: []
			})
		}
	});

	return { coinDropsData, refreshCoinDropsData };
};

export default useCoinDrops;
