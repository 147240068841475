import type { ScratchCardsInfoData } from "@/types";

const SKIN_CAMP = true;

const TICKET_COLORS = {
	bronze: {
		dark: "#ee3401",
		light: "#FC8701",
		inner: "rgba(177, 0, 0, 0.8)"
	},
	silver: {
		dark: "#0947AC",
		light: "#118FFA",
		inner: "rgba(7, 42, 97, 0.8)"
	},
	gold: {
		dark: "#FF740C",
		light: "#FCC301",
		inner: "rgba(198, 130, 0, 0.8)"
	}
};

const useScretchCardData = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const { data, refresh } = useAsyncFetch({
		path: "/rest/scratch-card-lottery/get/",
		method: "get",
		options: {
			immediate,
			cached: true,
			transform: (data) => data.data as ScratchCardsInfoData["data"]
		}
	});

	const activeCard = computed(() => {
		const indexReady = data.value?.cards?.findIndex((item) => item.status === "ready_for_activate") ?? -1;
		const indexAvaliable = data.value?.cards?.findIndex((item) => item.status === "available") ?? -1;
		if (indexReady !== -1) {
			return data.value?.cards?.[indexReady];
		}
		if (indexAvaliable !== -1) {
			return data.value?.cards?.[indexAvaliable];
		}
		return data.value?.cards?.[0];
	});

	const { durationLeft } = useCountdown({
		timestamp: data.value?.finishedAt ?? "",
		formatToken: "DD[D ] HH[H ]mm[M ]",
		isOptimized: true
	});

	return { data, refresh, activeCard, durationLeft, TICKET_COLORS, SKIN_CAMP };
};

export default useScretchCardData;
