import { toast } from "vue3-toastify";

import type { EventData, CoinDropsSocket } from "@/types";

const useCoinDropsSocket = () => {
	const { add, remove } = useSockets();
	const { open } = useNlcModals();
	const { data: appInitData } = useAppInitData();
	const { coinDropsData } = useCoinDrops({ immediate: false });

	const showToast = (data: CoinDropsSocket) => {
		toast.success(
			`<div>Coin drops</div>
				<div class="text-caracas">User ${data.playerName} won ${numberFormat(Number(data.prizeValue))} SC</div>
				<div class="text-caracas text">Keep playing and get prizes</div>`,
			{
				toastId: "coin-drops",
				icon: h("i", { class: "toast-icon icon-coin-drops" }),
				dangerouslyHTMLString: true,
				theme: toast.THEME.DARK,
				position: toast.POSITION.TOP_RIGHT,
				transition: toast.TRANSITIONS.SLIDE,
				closeButton: true,
				autoClose: 3000
			}
		);
	};

	add("prizeDrops", (dataSocket: EventData<CoinDropsSocket>) => {
		if (dataSocket.action === "prizeAssigned") {
			coinDropsData.value.prizes = dataSocket.data.board.prizes;
			coinDropsData.value.lastWinners = dataSocket.data.board.lastWinners;

			if (dataSocket.data.playerId === (appInitData.value?.login || "")) {
				open("LazyOModalCoinDropsGameCongratulations", {
					detail: { prizeValue: dataSocket.data.prizeValue, prizeLevel: dataSocket.data.prizeLevel }
				});
				return;
			}
			showToast(dataSocket.data);
		}
	});

	onUnmounted(() => {
		remove("prizeDrops");
	});
};

export default useCoinDropsSocket;
