import { default as indexoCRaogjdPFMeta } from "/app/apps/nlc/pages/affiliates/index.vue?macro=true";
import { default as partners_45accountC62hJKOON2Meta } from "/app/apps/nlc/pages/affiliates/partners-account.vue?macro=true";
import { default as coin_45dropseOaazjnesKMeta } from "/app/apps/nlc/pages/coin-drops.vue?macro=true";
import { default as collectionsHDIOSnNifdMeta } from "/app/apps/nlc/pages/collections.vue?macro=true";
import { default as gamesiS15hB8E3Meta } from "/app/apps/nlc/pages/game.vue?macro=true";
import { default as geoblockMNG0MrEgw6Meta } from "/app/apps/nlc/pages/geoblock.vue?macro=true";
import { default as how_45it_45worksPjtlW2MhFJMeta } from "/app/apps/nlc/pages/how-it-works.vue?macro=true";
import { default as indexTDgVeZq9tpMeta } from "/app/apps/nlc/pages/index.vue?macro=true";
import { default as _91pageName_93DDEDmK7MILMeta } from "/app/apps/nlc/pages/issues/[pageName].vue?macro=true";
import { default as favoritesBovQCHpUpcMeta } from "/app/apps/nlc/pages/issues/favorites.vue?macro=true";
import { default as modals_45showcasetRV6eNakSVMeta } from "/app/apps/nlc/pages/not-released/modals-showcase.vue?macro=true";
import { default as registerBnjwZD6odYMeta } from "/app/apps/nlc/pages/not-released/register.vue?macro=true";
import { default as translations_45showcasefxwHltoHFPMeta } from "/app/apps/nlc/pages/not-released/translations-showcase.vue?macro=true";
import { default as how_45it_45workspRCqE6T6EKMeta } from "/app/apps/nlc/pages/nuxt/how-it-works.vue?macro=true";
import { default as _91name_93ymR0QQACLYMeta } from "/app/apps/nlc/pages/page/[name].vue?macro=true";
import { default as promotions6kAgy5NcxUMeta } from "/app/apps/nlc/pages/promotions.vue?macro=true";
import { default as referralCH0RPvh5XmMeta } from "/app/apps/nlc/pages/referral.vue?macro=true";
import { default as scratch_45cards1wfYUEckWsMeta } from "/app/apps/nlc/pages/scratch-cards.vue?macro=true";
import { default as tournamentsEQjwoXR1tgMeta } from "/app/apps/nlc/pages/tournaments.vue?macro=true";
import { default as vipmC9IbczG1NMeta } from "/app/apps/nlc/pages/vip.vue?macro=true";
import { default as wheel_45of_45winst0jKQdmecqMeta } from "/app/apps/nlc/pages/wheel-of-wins.vue?macro=true";
export default [
  {
    name: indexoCRaogjdPFMeta?.name ?? "affiliates___en",
    path: indexoCRaogjdPFMeta?.path ?? "/affiliates",
    meta: indexoCRaogjdPFMeta || {},
    alias: indexoCRaogjdPFMeta?.alias || [],
    redirect: indexoCRaogjdPFMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: partners_45accountC62hJKOON2Meta?.name ?? "affiliates-partners-account___en",
    path: partners_45accountC62hJKOON2Meta?.path ?? "/affiliates/partners-account",
    meta: partners_45accountC62hJKOON2Meta || {},
    alias: partners_45accountC62hJKOON2Meta?.alias || [],
    redirect: partners_45accountC62hJKOON2Meta?.redirect,
    component: () => import("/app/apps/nlc/pages/affiliates/partners-account.vue").then(m => m.default || m)
  },
  {
    name: coin_45dropseOaazjnesKMeta?.name ?? "coin-drops___en",
    path: coin_45dropseOaazjnesKMeta?.path ?? "/coin-drops",
    meta: coin_45dropseOaazjnesKMeta || {},
    alias: coin_45dropseOaazjnesKMeta?.alias || [],
    redirect: coin_45dropseOaazjnesKMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/coin-drops.vue").then(m => m.default || m)
  },
  {
    name: collectionsHDIOSnNifdMeta?.name ?? "collections___en",
    path: collectionsHDIOSnNifdMeta?.path ?? "/collections",
    meta: collectionsHDIOSnNifdMeta || {},
    alias: collectionsHDIOSnNifdMeta?.alias || [],
    redirect: collectionsHDIOSnNifdMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/collections.vue").then(m => m.default || m)
  },
  {
    name: gamesiS15hB8E3Meta?.name ?? "game___en",
    path: gamesiS15hB8E3Meta?.path ?? "/game",
    meta: gamesiS15hB8E3Meta || {},
    alias: gamesiS15hB8E3Meta?.alias || [],
    redirect: gamesiS15hB8E3Meta?.redirect,
    component: () => import("/app/apps/nlc/pages/game.vue").then(m => m.default || m)
  },
  {
    name: geoblockMNG0MrEgw6Meta?.name ?? "geoblock___en",
    path: geoblockMNG0MrEgw6Meta?.path ?? "/geoblock",
    meta: geoblockMNG0MrEgw6Meta || {},
    alias: geoblockMNG0MrEgw6Meta?.alias || [],
    redirect: geoblockMNG0MrEgw6Meta?.redirect,
    component: () => import("/app/apps/nlc/pages/geoblock.vue").then(m => m.default || m)
  },
  {
    name: how_45it_45worksPjtlW2MhFJMeta?.name ?? "how-it-works___en",
    path: how_45it_45worksPjtlW2MhFJMeta?.path ?? "/how-it-works",
    meta: how_45it_45worksPjtlW2MhFJMeta || {},
    alias: how_45it_45worksPjtlW2MhFJMeta?.alias || [],
    redirect: how_45it_45worksPjtlW2MhFJMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: indexTDgVeZq9tpMeta?.name ?? "index___en",
    path: indexTDgVeZq9tpMeta?.path ?? "/",
    meta: indexTDgVeZq9tpMeta || {},
    alias: indexTDgVeZq9tpMeta?.alias || [],
    redirect: indexTDgVeZq9tpMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93DDEDmK7MILMeta?.name ?? "issues-pageName___en",
    path: _91pageName_93DDEDmK7MILMeta?.path ?? "/issues/:pageName()",
    meta: _91pageName_93DDEDmK7MILMeta || {},
    alias: _91pageName_93DDEDmK7MILMeta?.alias || [],
    redirect: _91pageName_93DDEDmK7MILMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/issues/[pageName].vue").then(m => m.default || m)
  },
  {
    name: favoritesBovQCHpUpcMeta?.name ?? "issues-favorites___en",
    path: favoritesBovQCHpUpcMeta?.path ?? "/issues/favorites",
    meta: favoritesBovQCHpUpcMeta || {},
    alias: favoritesBovQCHpUpcMeta?.alias || [],
    redirect: favoritesBovQCHpUpcMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/issues/favorites.vue").then(m => m.default || m)
  },
  {
    name: modals_45showcasetRV6eNakSVMeta?.name ?? "not-released-modals-showcase___en",
    path: modals_45showcasetRV6eNakSVMeta?.path ?? "/not-released/modals-showcase",
    meta: modals_45showcasetRV6eNakSVMeta || {},
    alias: modals_45showcasetRV6eNakSVMeta?.alias || [],
    redirect: modals_45showcasetRV6eNakSVMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/not-released/modals-showcase.vue").then(m => m.default || m)
  },
  {
    name: registerBnjwZD6odYMeta?.name ?? "not-released-register___en",
    path: registerBnjwZD6odYMeta?.path ?? "/not-released/register",
    meta: registerBnjwZD6odYMeta || {},
    alias: registerBnjwZD6odYMeta?.alias || [],
    redirect: registerBnjwZD6odYMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/not-released/register.vue").then(m => m.default || m)
  },
  {
    name: translations_45showcasefxwHltoHFPMeta?.name ?? "not-released-translations-showcase___en",
    path: translations_45showcasefxwHltoHFPMeta?.path ?? "/not-released/translations-showcase",
    meta: translations_45showcasefxwHltoHFPMeta || {},
    alias: translations_45showcasefxwHltoHFPMeta?.alias || [],
    redirect: translations_45showcasefxwHltoHFPMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/not-released/translations-showcase.vue").then(m => m.default || m)
  },
  {
    name: how_45it_45workspRCqE6T6EKMeta?.name ?? "nuxt-how-it-works___en",
    path: how_45it_45workspRCqE6T6EKMeta?.path ?? "/nuxt/how-it-works",
    meta: how_45it_45workspRCqE6T6EKMeta || {},
    alias: how_45it_45workspRCqE6T6EKMeta?.alias || [],
    redirect: how_45it_45workspRCqE6T6EKMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/nuxt/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: _91name_93ymR0QQACLYMeta?.name ?? "page-name___en",
    path: _91name_93ymR0QQACLYMeta?.path ?? "/page/:name()",
    meta: _91name_93ymR0QQACLYMeta || {},
    alias: _91name_93ymR0QQACLYMeta?.alias || [],
    redirect: _91name_93ymR0QQACLYMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/page/[name].vue").then(m => m.default || m)
  },
  {
    name: promotions6kAgy5NcxUMeta?.name ?? "promotions___en",
    path: promotions6kAgy5NcxUMeta?.path ?? "/promotions",
    meta: promotions6kAgy5NcxUMeta || {},
    alias: promotions6kAgy5NcxUMeta?.alias || [],
    redirect: promotions6kAgy5NcxUMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/promotions.vue").then(m => m.default || m)
  },
  {
    name: referralCH0RPvh5XmMeta?.name ?? "referral___en",
    path: referralCH0RPvh5XmMeta?.path ?? "/referral",
    meta: referralCH0RPvh5XmMeta || {},
    alias: referralCH0RPvh5XmMeta?.alias || [],
    redirect: referralCH0RPvh5XmMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: scratch_45cards1wfYUEckWsMeta?.name ?? "scratch-cards___en",
    path: scratch_45cards1wfYUEckWsMeta?.path ?? "/scratch-cards",
    meta: scratch_45cards1wfYUEckWsMeta || {},
    alias: scratch_45cards1wfYUEckWsMeta?.alias || [],
    redirect: scratch_45cards1wfYUEckWsMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/scratch-cards.vue").then(m => m.default || m)
  },
  {
    name: tournamentsEQjwoXR1tgMeta?.name ?? "tournaments___en",
    path: tournamentsEQjwoXR1tgMeta?.path ?? "/tournaments",
    meta: tournamentsEQjwoXR1tgMeta || {},
    alias: tournamentsEQjwoXR1tgMeta?.alias || [],
    redirect: tournamentsEQjwoXR1tgMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/tournaments.vue").then(m => m.default || m)
  },
  {
    name: vipmC9IbczG1NMeta?.name ?? "vip___en",
    path: vipmC9IbczG1NMeta?.path ?? "/vip",
    meta: vipmC9IbczG1NMeta || {},
    alias: vipmC9IbczG1NMeta?.alias || [],
    redirect: vipmC9IbczG1NMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/vip.vue").then(m => m.default || m)
  },
  {
    name: wheel_45of_45winst0jKQdmecqMeta?.name ?? "wheel-of-wins___en",
    path: wheel_45of_45winst0jKQdmecqMeta?.path ?? "/wheel-of-wins",
    meta: wheel_45of_45winst0jKQdmecqMeta || {},
    alias: wheel_45of_45winst0jKQdmecqMeta?.alias || [],
    redirect: wheel_45of_45winst0jKQdmecqMeta?.redirect,
    component: () => import("/app/apps/nlc/pages/wheel-of-wins.vue").then(m => m.default || m)
  }
]