import { toast } from "vue3-toastify";

import useAsyncFetch from "./useAsyncFetch";

type ModalOpen<K extends string, P> = (name: K, props?: P) => void;

const useWithoutDepositBonus = <K extends string, P, T extends (key: string) => string>(
	open: ModalOpen<K, P>,
	t: T
) => {
	const { isDesktop } = useDevice();
	const router = useRouter();

	const messageIdRef = ref<number | undefined>(undefined);

	const { execute: checkBonusExecute } = useAsyncFetch({
		path: "/rest/free-entries-bonuses/check-bonus/",
		method: "post",
		options: {
			immediate: false
		},
		fetchOptions: () => ({
			body: {
				message_id: messageIdRef.value
			},
			onResponse: ({ response }) => {
				const dataBonus = response?._data?.[0];

				if (dataBonus?.playerBonusInfo && dataBonus?.bonusInfo) {
					open(
						"LazyOModalWithoutDepositBonus" as K,
						{
							bonusId: dataBonus?.playerBonusInfo?.bonusId,
							timeEnd: dataBonus?.playerBonusInfo?.bonusLifetimeEnd,
							entries: dataBonus?.bonusInfo?.bonusAmount,
							messageId: Number(messageIdRef.value)
						} as P
					);
					return;
				}

				router.replace({ query: {} });
				toast.error(t("Bonus may be expired or unavailable"), {
					theme: toast.THEME.DARK,
					position: isDesktop ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_CENTER,
					transition: toast.TRANSITIONS.SLIDE,
					autoClose: 5000
				});
			}
		})
	});

	const checkPendingBonus = (messageId: string | number) => {
		messageIdRef.value = Number(messageId);
		checkBonusExecute();
	};

	return {
		checkPendingBonus
	};
};

export default useWithoutDepositBonus;
